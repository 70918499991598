import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonThumbnail, IonImg, IonInput, IonItem, IonGrid, IonRow, IonCol, IonButton, IonCard, IonCardHeader, IonCardTitle, IonCardContent,
  // useIonViewDidLeave, 
  // useIonViewDidEnter, 
  // useIonViewWillLeave,
  useIonViewWillEnter } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { RouteComponentProps , useHistory } from "react-router-dom";
import {PhotoSwipe} from 'react-photoswipe';
import Fuse from 'fuse.js'

import catalogue_de from '../data/catalogue_de.json';

import 'react-photoswipe/lib/photoswipe.css';
import './Search.css';

interface SearchDetailPageProps extends RouteComponentProps<{
  query: string;
}> {}

// const store = [
//   {
//     "title": "Old Man's War",
//     "author": {
//       "firstName": "John",
//       "lastName": "Scalzi"
//     }
//   },
//   {
//     "title": "The Lock Artist",
//     "author": {
//       "firstName": "Steve",
//       "lastName": "Hamilton"
//     }
//   },
//   {
//     "title": "HTML5",
//     "author": {
//       "firstName": "Remy",
//       "lastName": "Sharp"
//     }
//   },
//   {
//     "title": "Right Ho Jeeves",
//     "author": {
//       "firstName": "P.D",
//       "lastName": "Woodhouse"
//     }
//   },
//   {
//     "title": "The Code of the Wooster",
//     "author": {
//       "firstName": "P.D",
//       "lastName": "Woodhouse"
//     }
//   },
//   {
//     "title": "Thank You Jeeves",
//     "author": {
//       "firstName": "P.D",
//       "lastName": "Woodhouse"
//     }
//   },
//   {
//     "title": "The DaVinci Code",
//     "author": {
//       "firstName": "Dan",
//       "lastName": "Brown"
//     }
//   },
//   {
//     "title": "Angels & Demons",
//     "author": {
//       "firstName": "Dan",
//       "lastName": "Brown"
//     }
//   },
//   {
//     "title": "The Silmarillion",
//     "author": {
//       "firstName": "J.R.R",
//       "lastName": "Tolkien"
//     }
//   },
//   {
//     "title": "Syrup",
//     "author": {
//       "firstName": "Max",
//       "lastName": "Barry"
//     }
//   },
//   {
//     "title": "The Lost Symbol",
//     "author": {
//       "firstName": "Dan",
//       "lastName": "Brown"
//     }
//   },
//   {
//     "title": "The Book of Lies",
//     "author": {
//       "firstName": "Brad",
//       "lastName": "Meltzer"
//     }
//   },
//   {
//     "title": "Lamb",
//     "author": {
//       "firstName": "Christopher",
//       "lastName": "Moore"
//     }
//   },
//   {
//     "title": "Fool",
//     "author": {
//       "firstName": "Christopher",
//       "lastName": "Moore"
//     }
//   },
//   {
//     "title": "Incompetence",
//     "author": {
//       "firstName": "Rob",
//       "lastName": "Grant"
//     }
//   },
//   {
//     "title": "Fat",
//     "author": {
//       "firstName": "Rob",
//       "lastName": "Grant"
//     }
//   },
//   {
//     "title": "Colony",
//     "author": {
//       "firstName": "Rob",
//       "lastName": "Grant"
//     }
//   },
//   {
//     "title": "Backwards, Red Dwarf",
//     "author": {
//       "firstName": "Rob",
//       "lastName": "Grant"
//     }
//   },
//   {
//     "title": "The Grand Design",
//     "author": {
//       "firstName": "Stephen",
//       "lastName": "Hawking"
//     }
//   },
//   {
//     "title": "The Book of Samson",
//     "author": {
//       "firstName": "David",
//       "lastName": "Maine"
//     }
//   },
//   {
//     "title": "The Preservationist",
//     "author": {
//       "firstName": "David",
//       "lastName": "Maine"
//     }
//   },
//   {
//     "title": "Fallen",
//     "author": {
//       "firstName": "David",
//       "lastName": "Maine"
//     }
//   },
//   {
//     "title": "Monster 1959",
//     "author": {
//       "firstName": "David",
//       "lastName": "Maine"
//     }
//   }
// ];

const Search: React.FC<SearchDetailPageProps> = ({match}) => {
  // property for the current catalog
  let catalogue = catalogue_de.catalogue_de;
  // create the fuse option array
  const options = { includeScore: true, threshold: 0.1, keys: [ "article_description", "article_number" ] };
  // create the photoswipe options
  let psOptions = { bgOpacity: 0.3, closeEl:true, fullscreenEl: false, zoomEl: false, shareEl: false, tapToClose: true };
  // create the fuse index
  const index = Fuse.createIndex(options.keys, catalogue)
  // create the fuse object
  const fuse = new Fuse(catalogue, options, index);  
  // property for the search query from the input field
  const [query, setQuery] = useState<string>();
  // property for the search pattern, which is set by the loadResults function
  const [pattern, setPattern] = useState<string>();
  // property for the update flag, which controls whether the search pattern is updated or not
  const [update, setUpdate] = useState<boolean>();
  // property for the update flag, which controls whether the modal for photoswipe is open or not
  const [photoswipeIsOpen, setPhotoswipeIsOpen] = useState<boolean>(false);
  // property for the array, which controls which image should be displayed in the photoswipe modal
  const [image, setImage] = useState<[{src: string, w: number, h: number, title: string}]>([{src: '/assets/image/products/platzhalter.jpg', w: 1200, h: 629, title: 'Platzhalter'}]);

  // property for the history
  let history = useHistory();
  // property for the search results from fuse.js
  let searchResults = fuse.search(`${pattern}`);

  let searchInput;
  let searchButton;

  // effect hook to update the search results if the query property is changed
  useEffect(() => {
    console.log('useEffect is running');
    // reload the results, if the value of query has changed
    loadResults();
    // check if query is still undefined, than try to load the query parameter
    if(typeof query === 'undefined') {
      loadSearchValue();
    }
  });

  // will enter hook to load the search results if the user comes back to the app
  useIonViewWillEnter(() => {
    // check if the page reloads, shown on the history action "pop"
    if(history.action === 'POP') {
      loadSearchValue();
    }
  });

  // function to update the search pattern to run the fuse.js search function
  const loadResults = () => {
    // only update the results, if the update flag is true
    if(update) {
      // update the search pattern
      setPattern(query);
      // DEBUG BEGIN!!!
      console.log(catalogue);
      // DEBUG END!!!
      // at last, disable auto update
      setUpdate(false);
    }
  }

  // function to save the url parameter in the query property
  const loadSearchValue = () => {
    // set query to parameter extracted from the url
    setQuery(match.params.query);
    // activate the reload function via the update property
    setUpdate(true);
  }

  const handleOpenPhotoswipe = (imageUrl: string, imageTitle: string) => {
    // set the current Image depending on the element we want to open via photoswipe
    setImage([{src: imageUrl, w: 1200, h: 629, title: imageTitle}]);   
    // update the photoswipe property
    setPhotoswipeIsOpen(true);
  };

  const handleClosePhotoswipe = () => {
    // update the photoswipe property
    setPhotoswipeIsOpen(false);
  };

  const handleNoImage = (imageTarget:any, imageTitle: string) => {
    // set the image src to our placeholder image
    imageTarget.src = '/assets/image/products/platzhalter.jpg';
    // disable photoswipe
    imageTarget.attributes.getNamedItem("data-ps").value = "false";
  };

  // RENDER FUNCTION
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <a rel="noopener noreferrer" href="/" className="logo">
            <IonThumbnail slot="start">
              <IonImg src="/assets/image/logo-huennebeck.svg" />
            </IonThumbnail>
          </a>
          <IonGrid className="banner">
              <IonRow className="ion-align-items-center">
                <IonCol offset="8" size="3">Produktkatalog</IonCol>
              </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Search</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow className="ion-align-items-center">
              <IonCol offset="1" size="9">
                <IonItem>
                  <IonInput id="searchInput" value={query} name="query" placeholder="Artikelnummer/Beschreibung" ref={input => { searchInput = input; }} onBlur={() => { setQuery(searchInput.value); searchButton!.disabled = false }} onIonChange={() => { if(searchInput.value !== '') { searchButton.disabled = false; } else { searchButton.disabled = true; } }}></IonInput>
                  <IonButton size="large" expand="full" className="clearInput" onClick={() => { setQuery(''); searchButton.disabled = true }}>X</IonButton>
                </IonItem>
              </IonCol>
              <IonCol size="1">
                <IonButton size="large" expand="full" routerDirection='none' routerLink={`/search/${query}`} ref={button => { searchButton = button; }} onClick={() => { setUpdate(true); loadResults() } }>Suchen</IonButton>
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol offset="1" size="10">
                <div className="auto-grid">
                  { searchResults.map(result => {
                    const { id, article_description, article_group, article_number, weight } = result.item;
                    //const imageID = '553656';
                    const imageURI = `/assets/image/products/${article_number}.jpg`;
                    return (
                      <IonCard key={id}>
                        <div className="thumbnail" onClick={(event) => { if(event.currentTarget.children[0].attributes.getNamedItem("data-ps")!.value === "true") handleOpenPhotoswipe(imageURI, article_description); }}>
                          <IonImg src={imageURI} alt={article_description} data-ps="true" onIonError={ (event) => { handleNoImage(event.target!, article_description); } } />
                        </div>
                        <IonCardHeader>
                          <IonCardTitle>{article_description}</IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                          <table>
                            <tbody>
                              <tr>
                                <td><strong>Artikelnummer:</strong></td>
                                <td>{article_number}</td>
                              </tr>
                              <tr>
                                <td><strong>Artikelgruppe:</strong></td>
                                <td>{article_group}</td>
                              </tr>
                              <tr>
                                <td><strong>Gewicht:</strong></td>
                                <td>{weight}&nbsp;kg</td>
                              </tr>
                            </tbody>
                          </table>
                        </IonCardContent>

                      </IonCard>
                      );
                    })
                  }
                  {searchResults.length === 0 ? <div className="no-results"><p>Leider wurden keine Ergebnisse gefunden. Bitte versuchen Sie einen anderen Suchbegriff.</p></div> : ''}
                </div>
                <PhotoSwipe isOpen={photoswipeIsOpen} items={image} options={psOptions} onClose={handleClosePhotoswipe}/>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default Search;
