import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonThumbnail, IonImg, IonInput, IonItem, IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import React, { useState } from 'react';

import 'react-photoswipe/lib/photoswipe.css';
import './Home.css';

const Home: React.FC = () => {
  const [query, setQuery] = useState<string>();

  let searchInput;
  let searchButton;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <a rel="noopener noreferrer" href="/" className="logo">
            <IonThumbnail slot="start">
              <IonImg src="/assets/image/logo-huennebeck.svg" />
            </IonThumbnail>
          </a>
          <IonGrid className="banner">
              <IonRow className="ion-align-items-center">
                <IonCol offset="8" size="3">Produktkatalog</IonCol>
              </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Home</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow className="ion-align-items-center">
              <IonCol offset="1" size="9">
                <IonItem>
                  <IonInput value={query} name="query" placeholder="Artikelnummer/Beschreibung" ref={input => { searchInput = input; }} onBlur={() => { setQuery(searchInput.value); if(searchInput.value !== '') { searchButton.disabled = false; } else { searchButton.disabled = true; } }} onIonChange={() => { if(searchInput.value !== '') { searchButton.disabled = false; } else { searchButton.disabled = true; } }}></IonInput>
                </IonItem>
              </IonCol>
              <IonCol size="1">
                <IonButton size="large" expand="full" routerDirection="root" routerLink={`/search/${query}`} ref={button => { searchButton = button; }} disabled={true}>Suchen</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default Home;
